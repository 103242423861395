/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
// import PropTypes from 'prop-types';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { FiMenu } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { SlLocationPin } from 'react-icons/sl';
import Link from '@/components/atoms/Link';
import { Button, MaxContainer, Text } from '@/components/atoms';
import BrandImage from '@/components/atoms/BrandImage';
import { LoginLogos, NavBar, CPModal } from '@/components/molecules';
import SearchBar from '@/components/molecules/SearchBar';
import { SideMenu } from '@/components/organisms';
import useAutoLogin from '@/hooks/useAutoLogin';
import useDeviceType from '@/hooks/useDeviceType';
import styles from './header.module.scss';
import { getCookies } from '@/services/cookies';

const slogans = {
  runningva: 'Tienda de running',
  futbolva: 'Tienda de futbol',
  urbanva: 'Tienda de moda',
  trainingva: 'Tienda de fitness',
  outdoorva: 'Tienda de monataña',
  kidsva: 'Tienda para niños'
};
const logoBrandsWidthSlider = {
  runningva: '180.39px',
  futbolva: '157.6px',
  trainingva: '184.86px',
  outdoorva: '192.77px',
  kidsva: '112.5px',
  vaypol: '125.45px',
  '+sports': '141.89px'
};

const Header = ({ withoutNavbar, withoutBrandsSlider }) => {
  const autoLogin = useAutoLogin();
  const { isDesktop } = useDeviceType();
  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const storeId = useSelector((state) => state.store);
  const reduxBrands = useSelector((state) => state.navBar?.available_brands);
  const liveInfo = useSelector((state) => state.streaming);
  const [openCPmodal, setOpenCPmodal] = useState({
    open: false,
    code: '',
    province: ''
  });

  let availableBrand = [];
  if (reduxBrands) availableBrand = [...reduxBrands, { code: '+sports' }];
  let body;
  if (typeof window !== 'undefined') {
    body = document.querySelector('#body');
  }
  useEffect(() => {
    body.classList.remove('inactive');
    const { cpcode } = getCookies();
    const cpInfo = cpcode ? JSON.parse(cpcode) : {};
    setTimeout(() => {
      setOpenCPmodal({
        open: !cpInfo?.zip_code,
        code: cpInfo?.zip_code,
        province: cpInfo?.province,
        department: cpInfo?.department
      });
    }, 3000);
  }, []);

  const closeMenu = () => {
    body.classList.remove('inactive');
    setOpen(false);
  };
  const openMenu = () => {
    body.classList.add('inactive');
    setMounted(true);
    setOpen(true);
  };

  useEffect(() => {
    autoLogin();
  }, []);
  return (
    <>
      {open && mounted && (
        <SideMenu
          close={closeMenu}
          open={open}
          mounted={mounted}
        />
      )}
      <div className={styles.header}>
        <MaxContainer>
          {isDesktop && !withoutBrandsSlider && (
            <div className={styles.slider}>
              <div className={styles.headerBrands}>
                <div className={styles.leftFlag} />
                {availableBrand.map(({ code }) => {
                  return code !== storeId ? (
                    <Link
                      absolutePath
                      key={code}
                      href={code === 'vaypol' ? '/' : `/${code}`}
                    >
                      <BrandImage
                        brand={code}
                        height={15}
                        imageWidth={logoBrandsWidthSlider[code]}
                        width={logoBrandsWidthSlider[code] || 'auto'}
                        isDark
                      />
                    </Link>
                  ) : null;
                })}
                <div className={styles.rightFlag} />
              </div>
            </div>
          )}
          <div className={styles.container}>
            <div>
              <Link
                linkStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
                href="/"
              >
                <div className={styles.logo}>
                  <BrandImage
                    primary
                    color="var(--color-primary)"
                    height={isDesktop ? 25 : 20}
                    maxWidth="220px"
                    width={isDesktop ? '211.84px' : '169.47px'}
                    imageWidth={isDesktop ? '211.84px' : '169.47px'}
                  />
                </div>
                {storeId !== 'vaypol' && isDesktop && (
                  <Text
                    textColor="white"
                    textSize="xxs"
                    textStyle={{ marginTop: '5px' }}
                  >
                    {slogans[storeId]} <strong>de Vaypol</strong>
                  </Text>
                )}
              </Link>
            </div>
            {/* TODO: Agregar  isGalleryPage={isGalleryPage} para no recargar la página */}
            {isDesktop && <SearchBar />}
            <LoginLogos />
          </div>
        </MaxContainer>
        {isDesktop && (
          <MaxContainer>
            <Button
              width="none"
              startIcon={<SlLocationPin style={{ fontSize: '20px' }} />}
              textColor="white"
              label={
                <Text textStyle={{ display: 'flex', gap: '3px' }}>
                  {openCPmodal.code ? `Estás en` : 'Ingresá tu código postal'}
                  {openCPmodal.code && (
                    <Text textColor="var(--color-primary)">
                      {openCPmodal.province} ({openCPmodal.code})
                    </Text>
                  )}
                </Text>
              }
              ariaLabel="cargar código postal"
              withOutPadding
              style={{ height: 'auto', marginTop: '15px' }}
              onClick={() => {
                setOpenCPmodal({ ...openCPmodal, open: true });
              }}
            />
          </MaxContainer>
        )}
      </div>
      {!isDesktop && (
        <div
          id="header-sticky"
          style={{ position: !liveInfo.hasVideo ? 'sticky' : 'initial' }}
          className={styles.secondLine}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              gap: '1rem'
            }}
          >
            <div className={styles.button}>
              <Button
                id="close-sidemenu"
                withOutPadding
                variant="icon"
                onClick={openMenu}
                ariaLabel="Abrir menu lateral"
                startIcon={
                  <FiMenu
                    aria-hidden="true"
                    fontSize={30}
                    color="white"
                  />
                }
                brColor="#121212"
              />
            </div>
            <div className={styles.searcher}>
              {/* TODO: Agregar  isGalleryPage={isGalleryPage} para no recargar la página */}
              <SearchBar />
            </div>
          </div>
          <Button
            width="none"
            startIcon={<SlLocationPin style={{ fontSize: '20px' }} />}
            textColor="white"
            label={
              <Text
                textStyle={{
                  display: 'flex',
                  gap: '3px',
                  flexWrap: 'wrap',
                  textAlign: 'start'
                }}
                textSize="s"
              >
                {openCPmodal.code ? `Estás en` : 'Ingresá tu código postal'}
                {openCPmodal.code && (
                  <Text
                    textColor="var(--color-primary)"
                    textSize="s"
                  >
                    {openCPmodal.province} ({openCPmodal.code})
                  </Text>
                )}
              </Text>
            }
            ariaLabel="cargar código postal"
            withOutPadding
            style={{
              height: 'auto',
              marginTop: '10px',
              alignItems: 'self-start'
            }}
            onClick={() => {
              setOpenCPmodal({ ...openCPmodal, open: true });
            }}
          />
        </div>
      )}
      {openCPmodal.open && (
        <CPModal
          onClose={setOpenCPmodal}
          saved={{
            province: openCPmodal.province,
            department: openCPmodal.department,
            code: openCPmodal.code
          }}
        />
      )}
      {isDesktop && !withoutNavbar && (
        <MaxContainer>
          <div className={styles.navbar}>
            <NavBar />
          </div>
        </MaxContainer>
      )}
    </>
  );
};

Header.propTypes = {
  withoutNavbar: PropTypes.bool,
  withoutBrandsSlider: PropTypes.bool
};
Header.defaultProps = {
  withoutNavbar: false,
  withoutBrandsSlider: false
};

export default Header;
