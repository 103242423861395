import axios from '../../services/axiosInstance';
// import errorHandler from '../error/handler';

const get = async (value, storeId) => {
  try {
    const response = await axios.get(
      `/products/search?keywords=${value}&code=${storeId}`
    );
    return {
      success: true,
      data: {
        products: response.data.data.products,
        brands: response.data.data.brands,
        sports: response.data.data.sports
      }
    };
  } catch (err) {
    // const message = errorHandler(err);

    return {
      success: false,
      error: 'message'
    };
  }
};

export default {
  get
};
